<script setup>
const props = defineProps({
	size: {
		type: Number,
		default: 48,
	},
	rank: {
		type: [String, Number],
		default: '',
	},
})

const cssSize = computed(() => `${props.size}px`)
</script>

<template>
	<span
		class="theme-ranking-label"
		:class="{
			'has-ranking': rank,
		}"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
		>
			<text
				v-if="rank"
				class="rank"
				transform="matrix(1 0 0 1 24 33.1289)"
				text-anchor="middle"
			>
				{{
					rank.toLocaleString('en-US', {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})
				}}
			</text>
			<i class="str-brand-top500"></i>
		</svg>
	</span>
</template>

<style lang="scss" scoped>
.theme-ranking-label {
	display: inline-block;
	--label-size: v-bind(cssSize);
	width: v-bind(cssSize);
	height: v-bind(cssSize);
}
</style>
