<script setup>
import { openDialogById, closeAllDialogs } from '@/helpers'
// import { router } from '@inertiajs/vue3'

const appStore = useAppStore()

const ageConsentDialog = ref(null)

const oldConsent = JSON.parse(localStorage.getItem('__ont-age-consent'))

if (oldConsent) {
	appStore.ageConsent = oldConsent
	localStorage.removeItem('__ont-age-consent')
}
function gtag(event) {
	if (import.meta.env.PROD) {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push(event)
	}
}

onMounted(() => {
	window.addEventListener('ont-age-check', () => {
		if (typeof window.ontAgeCheck !== 'undefined' && window.ontAgeCheck && appStore.ageConsent.shown && !appStore.ageConsent.value) {
			window.location = '/'
			// router.visit(route('home')) // Creates a weird loop?
		} else if (typeof window.ontAgeCheck !== 'undefined' && window.ontAgeCheck && !appStore.ageConsent.shown) {
			// ageConsentDialog.value.showModal()
			openDialogById('age-consent-dialog')

			ageConsentDialog.value.addEventListener('close', () => {
				const choice = ageConsentDialog.value.returnValue === '1' ? true : false

				appStore.ageConsent = {
					shown: true,
					value: choice,
				}

				gtag({ event: 'age_check', over23: choice ? 'yes' : 'no' })

				// For enabling scrolling
				closeAllDialogs()

				if (!choice) {
					window.location = '/'
					// router.visit(route('home')) // Creates a weird loop?
				}
			})
		}
	})

	// If hard refresh, trigger age check
	dispatchEvent(new Event('ont-age-check'))
})
</script>

<template>
	<dialog
		id="age-consent-dialog"
		ref="ageConsentDialog"
		class="dialog-modal dialog-modal-top shadow mt-3"
		style="max-width: 320px"
	>
		<form
			ref="ageConsentForm"
			class="modal-content"
		>
			<div class="modal-header justify-content-between px-md-4">
				<h4 class="modal-title">
					{{ $t('AgeConsentHeading') }}
				</h4>
				<i
					class="str-close cursor-pointer"
					role="button"
					@click="$refs.ageConsentDialog.close(0)"
				/>
			</div>
			<div class="modal-body p-md-4">
				{{ $t('AgeConsentText') }}
			</div>
			<div class="modal-footer px-md-4">
				<div
					type="button"
					class="btn btn-theme-500 fw-bold w-100"
					@click="$refs.ageConsentDialog.close(1)"
				>
					{{ $t('AgeConsentConfirm') }}
				</div>
				<div
					type="button"
					class="btn btn-outline-neutral-900 fw-bold w-100"
					@click="$refs.ageConsentDialog.close(0)"
				>
					{{ $t('AgeConsentDecline') }}
				</div>
			</div>
		</form>
	</dialog>
</template>

<style lang="scss">
#age-consent-dialog {
	&::backdrop {
		backdrop-filter: blur(5px);
	}
}
</style>
