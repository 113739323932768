<script setup>
import { closeDialog, openDialogById } from '@/helpers'
import { useForm } from '@inertiajs/vue3'

const appStore = useAppStore()

const form = useForm({
	firstName: null,
	lastName: null,
	email: null,
	password: null,
	password_confirmation: null,
})

const preview = ref(false)
const preview_second = ref(false)

const submit = () => {
	form.post('/register', {
		preserveScroll: true,
		onSuccess: () => {
			openDialogById('login-modal-dialog')
		},
		onError: () => {
			// Reopen dialog
			openDialogById('register-modal-dialog')
		},
	})
}

onMounted(() => {
	document.getElementById('register-modal-dialog').addEventListener('close', () => {
		form.reset()
		form.clearErrors()
	})
})
</script>

<template>
	<dialog
		id="register-modal-dialog"
		ref="registerModalDialog"
		class="dialog-modal dialog-modal-bottom-sm shadow"
		style="--lek-modal-border-width: 0px"
		aria-hidden="true"
	>
		<div class="modal-content">
			<!-- <div class="modal-header justify-content-between">
				<h6 class="mb-0">{{ $t('Register') }}</h6>
				<i
					class="str-close cursor-pointer"
					role="button"
					@click="
						() => {
							closeDialog($refs.registerModalDialog)
							form.clearErrors()
							form.reset()
						}
					"
				/>
			</div> -->
			<div class="modal-header d-block border-0">
				<div class="text-end">
					<i
						class="str-close cursor-pointer"
						role="button"
						@click="
							() => {
								closeDialog($refs.registerModalDialog)
								form.clearErrors()
								form.reset()
							}
						"
					/>
				</div>
				<h4 class="modal-title fs-4 mt-n3 mb-0">{{ $t('Register') }}</h4>
			</div>

			<form @submit.prevent="submit()">
				<div class="modal-body pt-0">
					<div class="row">
						<div class="col-12 mb-3">
							<input
								v-model="form.firstName"
								type="text"
								class="form-control"
								:placeholder="$t('First name')"
								required
							/>
							<div v-if="form.errors.firstName">
								<small
									v-for="(firstNameError, firstNameErrorIndex) in form.errors.firstName"
									:key="`firstname-error-${firstNameErrorIndex}`"
									class="text-danger"
									>{{ firstNameError }}</small
								>
							</div>
						</div>
						<div class="col-12 mb-3">
							<input
								v-model="form.lastName"
								type="text"
								class="form-control"
								:placeholder="$t('Last name')"
								required
							/>
							<div v-if="form.errors.lastName">
								<small
									v-for="(lastNameError, lastNameErrorIndex) in form.errors.lastName"
									:key="`lastname-error-${lastNameErrorIndex}`"
									class="text-danger me-1"
									>{{ lastNameError }}</small
								>
							</div>
						</div>
						<div class="col-12 mb-3">
							<input
								v-model="form.email"
								type="email"
								class="form-control"
								:placeholder="$t('Email')"
								required
							/>
							<div v-if="form.errors.email">
								<small
									v-for="(emailError, emailErrorIndex) in form.errors.email"
									:key="`email-error-${emailErrorIndex}`"
									class="text-danger me-1"
									>{{ emailError }}</small
								>
							</div>
						</div>
						<div class="col-12 mb-3">
							<div class="password-wrapper">
								<input
									v-model="form.password"
									:type="!preview ? 'password' : 'text'"
									class="form-control"
									:placeholder="$t('Password')"
									required
									autocomplete="off"
								/>
								<a
									class="icon-wrap"
									href="javascript:void(0);"
									role="button"
									@click="preview = !preview"
								>
									<i
										:class="{
											'bi-eye': preview,
											'bi-eye-slash': !preview,
										}"
									/>
								</a>
							</div>
							<div v-if="form.errors.password">
								<small
									v-for="(passwordError, passwordErrorIndex) in form.errors.password"
									:key="`password-error-${passwordErrorIndex}`"
									class="text-danger me-1"
									>{{ passwordError }}</small
								>
							</div>
						</div>
						<div class="col-12 mb-3">
							<div class="password-wrapper">
								<input
									v-model="form.password_confirmation"
									:type="!preview_second ? 'password' : 'text'"
									class="form-control"
									:placeholder="$t('Password')"
									required
									autocomplete="off"
								/>
								<a
									class="icon-wrap"
									href="javascript:void(0);"
									role="button"
									@click="preview_second = !preview_second"
								>
									<i
										:class="{
											'bi-eye': preview_second,
											'bi-eye-slash': !preview_second,
										}"
									/>
								</a>
							</div>
							<div v-if="form.errors.password_confirmation">
								<small
									v-for="(passwordConfirmError, passwordConfirmErrorIndex) in form.errors.password_confirmation"
									:key="`password-confirm-error-${passwordConfirmErrorIndex}`"
									class="text-danger me-1"
									>{{ passwordConfirmError }}</small
								>
							</div>
						</div>
						<div class="col-12 d-flex">
							<button
								type="button"
								:disabled="form.processing"
								class="btn btn-theme-500 fw-bold prevent-modal-close"
								@click="submit()"
							>
								{{ $t('Send') }}
							</button>
							<div class="flex-grow-1 text-end">
								<a
									href="javascript:void(0);"
									role="button"
									class="feature-link"
									@click="openDialogById('login-modal-dialog')"
								>
									{{ $t('Go to login') }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</dialog>
</template>

<style lang="scss">
.password-wrapper {
	position: relative;
	.icon-wrap {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}
}
</style>
