<script setup>
import { Link, router } from '@inertiajs/vue3'
import { closeDialog, openDialogById } from '@/helpers'
import { languages } from '@/languages'

const appStore = useAppStore()
const wizardStore = useWizardStore()

const props = defineProps({
	navigation: {
		type: Array,
		default: () => [],
	},
})

const logout = () => {
	router.post(route('logout'))
}
</script>

<template>
	<dialog
		id="main-nav-dialog"
		ref="mainNavDialog"
		class="dialog-modal dialog-modal-right-full shadow"
		style="--lek-modal-border-radius: 0; --lek-modal-border-width: 0px"
		aria-hidden="true"
	>
		<div class="modal-content">
			<div class="modal-header justify-content-end py-2">
				<i
					class="str-close cursor-pointer"
					role="button"
					style="font-size: 18px"
					@click="closeDialog($refs.mainNavDialog)"
				/>
			</div>
			<div
				class="modal-body d-md-flex flex-column justify-content-around px-md-5"
				style="overflow-y: scroll"
				data-scroll-lock-scrollable
			>
				<nav>
					<ul
						class="main-nav"
						style="--gap: 0.75rem"
					>
						<li
							v-for="nav in navigation"
							:key="`nav-${nav.label}`"
							class="main-nav-item"
							:class="{
								active: $page.component === nav.component && $page.url.startsWith(nav.route), // Maybe only check route?
								'fw-bold': $page.component === nav.component && $page.url.startsWith(nav.route), // Maybe only check route?
							}"
						>
							<span
								v-if="nav.label === 'navigation.main.search'"
								class="cursor-pointer hover-underline"
								@click="openDialogById('search-wizard-dialog')"
							>
								{{ $t(nav.label) }}
							</span>
							<Link
								v-else-if="nav.label === 'navigation.main.restaurants'"
								:href="wizardStore.query"
							>
								{{ $t(nav.label) }}
							</Link>
							<Link
								v-else
								:href="nav.route"
							>
								{{ $t(nav.label) }}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div class="modal-footer d-block px-md-5">
				<auth-check>
					<Link
						as="button"
						type="button"
						class="btn btn-theme-500 py-2 fw-bolder"
						:href="route('register')"
					>
						{{ $t('Become a member') }}
					</Link>
					<button
						type="button"
						class="btn btn-outline-neutral-900 py-2 fw-bolder"
						@click="openDialogById('login-modal-dialog')"
					>
						{{ $t('Login') }}
					</button>
					<template #auth="{ user }">
						<button
							type="button"
							class="btn btn-outline-neutral-900 py-2 fw-bolder"
							@click="logout"
						>
							{{ $t('Logout') }}
						</button>
					</template>
				</auth-check>
				<div v-if="appStore.translationsEnabled">
					<ul class="main-nav main-nav-horizontal mt-3">
						<li
							v-for="lang in languages"
							:key="`lang-switch-${lang.i18n}`"
							class="main-nav-item"
							:class="{
								active: appStore.i18n === lang.value,
								'fw-bold': appStore.i18n === lang.value,
							}"
						>
							<button
								class="btn btn-link p-0"
								:class="{
									'fw-bold': appStore.i18n === lang.value,
								}"
								@click="
									() => {
										if (appStore.i18n !== lang.value) appStore.i18n = lang.value
									}
								"
							>
								{{ $t(lang.longLabel) }}
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</dialog>
</template>
